import React from 'react'
import { Link } from 'react-router-dom'

const SectionThree = () => {
    const myStyle = {
        backgroundImage: "url('images/exercise-back.jpg')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      };
      
  return (
    <>
        <div style={myStyle}>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-12 mt-5 exercise-heading-color">
            <h4 className="text-center">Exercies And Quizzes</h4>
            <h6 className="text-center">Test Your Skills</h6>
          </div>
        </div>
      </div>
      <div style={{ width: "80%", margin: "auto" }}>

        <div className="row text-center mt-3 pb-3">
          <div className="col-sm mx-auto mt-3">
              <Link className="mx-auto mt-3 exercise_box" to={"/course/exercise-test"} style={{ textDecoration: "none" }}>
                <h3 className="exercise-heading">Exercies</h3>
              </Link>
          </div>

          <div className="col-sm mx-auto mt-3">
              <Link className="mx-auto mt-3 exercise_box" to={"/course/tryeditor"} style={{ textDecoration: "none" }}>
                <h3 className="exercise-heading">Try Editor</h3>
              </Link>
          </div>
        </div>
      </div>
    </div> 
    </>
  )
}

export default SectionThree
