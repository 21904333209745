import React, { useEffect, useState } from 'react';
import SectionOne from './SectionOne';
import SectionFour from './SectionFour';
import SectionThree from './SectionThree';
import SectionTwo from './SectionTwo';
import axios from 'axios';

const Home = () => {
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get('http://localhost:3002/course/get_topic')
      .then(function (response) {
        // console.log(response.data.data);
        console.log("hello");
        setData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <>
      <SectionOne />
      <SectionTwo data={data} />
      <SectionThree />
      <SectionFour />
    </>
  );
};

export default Home;
