import React, { useEffect } from 'react';
import Sidebar from './Common/Sidebar';
import Contentarea from './Common/Contentarea';

const C = () => {
  useEffect(() => {
    hankdegetdata();
  }, []);

  const hankdegetdata = async () => {
    try {
      // const res = fetch();
    } catch (err) {}
  };
  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-2 p-0'>
            <Sidebar />
          </div>
          <div className='col-md-8 contentdiv'>
            <Contentarea />
          </div>
          <div className='col-md-2'>Ads By Google</div>
        </div>
      </div>
    </>
  );
};

export default C;
