import React, { useEffect, useState } from 'react';
import '../Login/login.css';
import { Link, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import OTPInput from 'react-otp-input';
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBNZ_fDPy9SC2qmEfxLOMkMGU2TuzB35xA',
  authDomain: 'codingveda-921b2.firebaseapp.com',
  projectId: 'codingveda-921b2',
  storageBucket: 'codingveda-921b2.appspot.com',
  messagingSenderId: '432109628583',
  appId: '1:432109628583:web:090a7e9d9ac23464c97f82',
  measurementId: 'G-WLE7YTDTNH',
};

const LoginAdmin = () => {
  const app = initializeApp(firebaseConfig);
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false);
  const [hideSocialLogin, setHideSocialLogin] = useState(true);
  const [btnValue, setBtnValue] = useState('Send OTP');
  const [otp, setOtp] = useState('');
  const [number, setNumber] = useState('');
  const [otpbtnHide, setOtpBtnHide] = useState(false);
  const [confirmResult, setConfirmResult] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState();

  const auth = getAuth();

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
      },
      auth
    );
  }, []);

  const sendOTP = () => {
    const phoneNumber = '+91' + number;
    const appVerifier = window.recaptchaVerifier;

    const auth = getAuth();
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        setConfirmResult(confirmationResult);
        setOtpBtnHide(true);
        setHideSocialLogin(false);
        setBtnValue('Submit');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyOTP = () => {
    confirmResult
      .confirm(otp)
      .then((result) => {
        const user = result.user;
        alert('success');
        navigate('/admin/admin');
      })
      .catch((error) => {
        alert('failed');
      });
  };

  const [showTimer, setShowTimer] = useState(true);

  function onChange(value) {
    setVerified(true);
  }

  useEffect(() => {
    if (showTimer) {
      const timer = setTimeout(() => {
        setShowTimer(false);
      }, 9000);

      return () => clearTimeout(timer);
    }
  }, [showTimer]);
  return (
    <>
      <div className='container p-4'>
        <div className='row'>
          <div className='col-md-7'>
            <img className='lottie_files w-100' style={{ maxWidth: '400px' }} src='./images/exams.gif' />
          </div>
          <div className='col-md-5 col-lg-4 col-xl-5'>
            <form className='form_background'>
              {hideSocialLogin ? (
                ''
              ) : (
                <div>
                  {showTimer ? (
                    <div className='alert alert-success alert-dismissible fade show' role='alert'>
                      <strong>OTP</strong> Send Successfully
                    </div>
                  ) : null}
                </div>
              )}

              <table>
                <tr>
                  <th>
                    <label htmlFor='email'>Mobile :</label>
                  </th>
                  {hideSocialLogin ? (
                    <td>
                      <input
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        type='number'
                        placeholder='Enter a valid Mobile Number'
                        className='email-input'
                      />
                    </td>
                  ) : (
                    <span>{number}</span>
                  )}
                </tr>
                <br />
                {otpbtnHide && (
                  <tr>
                    <th>
                      <label htmlFor='OTP'>OTP:</label>
                    </th>
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span> &nbsp;&nbsp; </span>}
                      renderInput={(props, index) => <input {...props} type=' ' style={{ width: '30px' }} />}
                    />
                  </tr>
                )}
              </table>
              <br />
              {hideSocialLogin && (
                <tr className='text-center'>
                  <ReCAPTCHA sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' onChange={onChange} className='mt-3' />
                </tr>
              )}
              <div className='text-center text-lg-start mt-4 pt-2'>
                <button
                  type='button'
                  className='btn btn-primary btn-lg w-50'
                  disabled={!verified}
                  onClick={() => {
                    if (confirmResult) {
                      verifyOTP();
                    } else {
                      sendOTP();
                    }
                  }}
                >
                  {btnValue}
                </button>
              </div>

              {hideSocialLogin && (
                <>
                  <div className='divider d-flex align-items-center my-4'>
                    <p className='text-center fw-bold mx-3 mb-0 or_design'>Or</p>
                  </div>
                  <div className='d-flex flex-row align-items-center justify-content-center justify-content-lg-start'>
                    <p className='sign_in_font'>Sign in with</p>

                    <Link className='btn btn-outline-light btn-floating m-1' to='' role='button'>
                      <img className='social_icon' alt='facebook' src='./images/search.png' />
                    </Link>
                    <Link className='btn btn-outline-light btn-floating m-1' to='' role='button'>
                      <img className='social_icon' alt='facebook' src='./images/facebook.png' />
                    </Link>
                    <Link className='btn btn-outline-light btn-floating m-1' to='' role='button'>
                      <img className='social_icon' alt='facebook' src='./images/twitter.png' />
                    </Link>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginAdmin;
