import React from "react";

const SectionFour = () => {
  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-12 mt-5">
            <h4 className="text-center">Follow Us</h4>
            <h6 className="text-center">
              Ready to start increase your knowledge with www.coddingveda.com
            </h6>
          </div>
        </div>
        <div className="row text-center mt-3 pb-3">
                <div className="col-md-1 col-lg-1 col-xl-1 mx-auto mt-3">
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                    <a className="btn bgcolor1" href="https://www.facebook.com/lokesh.rewar">
                        <i className="fa fa-facebook"></i>
                    </a>
                </div>

                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                    <a className="btn bgcolor2" href="https://twitter.com/LokeshRewar">
                        <i className="fa fa-twitter"></i>
                    </a>
                </div>

                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                    <a className="btn bgcolor3" href="https://www.instagram.com/coddingzone/">
                        <i className="fa fa-instagram"></i>
                    </a>
                </div>

                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                    <a className="btn bgcolor4" href="https://www.youtube.com/futureexam1">
                        <i className="fa fa-youtube-play"></i>
                    </a>
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                    <a className="btn bgcolor5" href="https://wa.me/9521133660">
                        <i className="fa fa-whatsapp"></i>
                    </a>
                </div>
                <div className="col-md-1 col-lg-1 col-xl-1 mx-auto mt-3">
                </div>
            </div>
      </div>
    </>
  );
};

export default SectionFour;
