import React from 'react';
import { Link } from 'react-router-dom';

const SectionTwo = ({ data = {} }) => {
  console.log('lokesh', data);
  const dataEntries = Object.entries(data);
  return (
    <>
      <div className='container-fluid course_bg'>
        <div className='row text-center text-white'>
          <h4 className='mt-1'>Our Courses</h4>
        </div>
        <div className='row text-center justify-content-center'>
          {dataEntries.map(([key, item], index) => (
            <div className='col-md-5' style={{ marginBottom: '10px' }}>
              <div className='card html_bg' style={{ backgroundColor: item.bgcolor }}>
                <div className='card-title'>
                  <h2>
                    <b style={{ textTransform: 'uppercase' }}>{item?.name}</b>
                  </h2>
                </div>
                <div className='card-body'>
                  <h5>{item?.title}</h5>
                  <Link to={item?.path} type='button' className='card_button mt-4 p-1'>
                    Learn {item?.name}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <br />
      </div>
    </>
  );
};

export default SectionTwo;

// import React from 'react';
// import { Link } from 'react-router-dom';

// let topic = [
//   {
//     path: '/C',
//     bgColor: '#FFF4A3',
//     title: 'C is a general-purpose programming language',
//     value: 'C',
//   },
//   {
//     path: '',
//     bgColor: '#A1C298',
//     title: 'C++ is a popular programming language',
//     value: 'C++',
//   },
//   {
//     path: '',
//     bgColor: '#C0C0C0',
//     title: 'Popular programming language',
//     value: 'Java',
//   },
//   {
//     path: '',
//     bgColor: '#FAEBD7',
//     title: 'A machine that can store and process information',
//     value: 'Computer',
//   },
//   {
//     path: '',
//     bgColor: '#BC8F8F',
//     title: 'The language for programming web pages',
//     value: 'JavaScript',
//   },
//   {
//     path: '',
//     bgColor: '#87CEFA',
//     title: 'The language for building web pages',
//     value: 'Html',
//   },
//   {
//     path: '',
//     bgColor: '#A9DFBF',
//     title: 'The language for styling web pages',
//     value: 'CSS',
//   },
//   {
//     path: '',
//     bgColor: '#A3E4D7',
//     title: 'A web server programming language',
//     value: 'Php',
//   },
//   {
//     path: '',
//     bgColor: '#E3F2C1',
//     title: 'MySQL is a relational database management system',
//     value: 'MySql',
//   },
//   {
//     path: '',
//     bgColor: '#D7BDE2',
//     title: 'A CSS framework for designing better web pages',
//     value: 'BootStrap',
//   },
//   {
//     path: '',
//     bgColor: '#E6B0AA',
//     title: 'Microsoft Office is a suite of desktop productivity applications',
//     value: 'MsOffice',
//   },
// ];

// const SectionTwo = ({ data = {} }) => {
//   console.log('lokesh', data);
//   return (
//     <>
//       <div className='container-fluid course_bg'>
//         <div className='row text-center text-white'>
//           <h4 className='mt-1'>Our Courses</h4>
//         </div>
//         <div className='row text-center justify-content-center'>
//           {topic.map((item, index) => (
//             <div className='col-md-5' style={{ marginBottom: '10px' }}>
//               <div className='card html_bg' style={{ backgroundColor: item.bgColor }}>
//                 <div className='card-title'>
//                   <h2>
//                     <b>{item.value}</b>
//                   </h2>
//                 </div>
//                 <div className='card-body'>
//                   <h5>{item.title}</h5>
//                   <Link to={item.path} type='button' className='card_button mt-4 p-1'>
//                     Learn {item.value}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//         <br />
//       </div>
//     </>
//   );
// };

// export default SectionTwo;
