import React from 'react';
import '../AdminPannel/Admin.css';
import { BsCalendarCheck } from 'react-icons/bs';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { BiTask } from 'react-icons/bi';
import { BiComment } from 'react-icons/bi';
import { FiLogOut } from 'react-icons/fi';
import { BsPencilSquare } from 'react-icons/bs';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Admin = () => {
  return (
    <>
      <div id='wrapper'>
        <ul class='navbar-nav bg-gradient-primary sidebar sidebar-dark accordion' id='accordionSidebar'>
          <a class='sidebar-brand d-flex align-items-center justify-content-center' href='index.html'>
            <div class='sidebar-brand-icon rotate-n-15'>
              <i class='fas fa-laugh-wink'></i>
            </div>
            <div class='sidebar-brand-text mx-3'>CodingVeda</div>
          </a>

          <hr class='sidebar-divider my-0' />

          <li class='nav-item active'>
            <a class='nav-link' href='index.html'>
              <i class='fas fa-fw fa-tachometer-alt'></i>
              <span>Dashboard</span>
            </a>
          </li>

          <hr class='sidebar-divider' />

          <div class='sidebar-heading'>Interface</div>

          <li class='nav-item'>
            <a
              class='nav-link collapsed'
              href='#'
              data-toggle='collapse'
              data-target='#collapseTwo'
              aria-expanded='true'
              aria-controls='collapseTwo'
            >
              <i class='fas fa-fw fa-cog'></i>
              <span>Components</span>
            </a>
            <div id='collapseTwo' class='collapse' aria-labelledby='headingTwo' data-parent='#accordionSidebar'>
              <div class='bg-white py-2 collapse-inner rounded'>
                <h6 class='collapse-header'>Custom Components:</h6>
                <a class='collapse-item' href='buttons.html'>
                  Buttons
                </a>
                <a class='collapse-item' href='cards.html'>
                  Cards
                </a>
              </div>
            </div>
          </li>

          <li class='nav-item'>
            <a
              class='nav-link collapsed'
              href='#'
              data-toggle='collapse'
              data-target='#collapseUtilities'
              aria-expanded='true'
              aria-controls='collapseUtilities'
            >
              <i class='fas fa-fw fa-wrench'></i>
              <span>Utilities</span>
            </a>
            <div
              id='collapseUtilities'
              class='collapse'
              aria-labelledby='headingUtilities'
              data-parent='#accordionSidebar'
            >
              <div class='bg-white py-2 collapse-inner rounded'>
                <h6 class='collapse-header'>Custom Utilities:</h6>
                <a class='collapse-item' href='utilities-color.html'>
                  Colors
                </a>
                <a class='collapse-item' href='utilities-border.html'>
                  Borders
                </a>
                <a class='collapse-item' href='utilities-animation.html'>
                  Animations
                </a>
                <a class='collapse-item' href='utilities-other.html'>
                  Other
                </a>
              </div>
            </div>
          </li>

          <hr class='sidebar-divider' />
          <div class='sidebar-heading'>Addons</div>

          <li class='nav-item'>
            <a
              class='nav-link collapsed'
              href='#'
              data-toggle='collapse'
              data-target='#collapsePages'
              aria-expanded='true'
              aria-controls='collapsePages'
            >
              <i class='fas fa-fw fa-folder'></i>
              <span>Pages</span>
            </a>
            <div id='collapsePages' class='collapse' aria-labelledby='headingPages' data-parent='#accordionSidebar'>
              <div class='bg-white py-2 collapse-inner rounded'>
                <h6 class='collapse-header'>Login Screens:</h6>
                <a class='collapse-item' href='login.html'>
                  Login
                </a>
                <a class='collapse-item' href='register.html'>
                  Register
                </a>
                <a class='collapse-item' href='forgot-password.html'>
                  Forgot Password
                </a>
                <div class='collapse-divider'></div>
                <h6 class='collapse-header'>Other Pages:</h6>
                <a class='collapse-item' href='404.html'>
                  404 Page
                </a>
                <a class='collapse-item' href='blank.html'>
                  Blank Page
                </a>
              </div>
            </div>
          </li>

          <li class='nav-item'>
            <a class='nav-link' href='charts.html'>
              <i class='fas fa-fw fa-chart-area'></i>
              <span>Charts</span>
            </a>
          </li>

          <li class='nav-item'>
            <a class='nav-link' href='tables.html'>
              <i class='fas fa-fw fa-table'></i>
              <span>Tables</span>
            </a>
          </li>
          <hr class='sidebar-divider d-none d-md-block' />
        </ul>

        <div id='content-wrapper' class='d-flex flex-column'>
          <div id='content'>
            <div class='container-fluid'>
              <div class='d-sm-flex align-items-center justify-content-between mb-4'>
                <h1 class='h3 mb-0 text-gray-800'>Dashboard</h1>
                <a href='#' class='d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm'>
                  <FiLogOut />
                </a>
              </div>

              <div class='row'>
                <div class='col-xl-3 col-md-6 mb-4'>
                  <div class='card border-left-primary shadow h-100 py-2'>
                    <div class='card-body'>
                      <div class='row no-gutters align-items-center'>
                        <div class='col mr-2'>
                          <div class='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                            Earnings (Monthly)
                          </div>
                          <div class='h5 mb-0 font-weight-bold text-gray-800'>$40,000</div>
                        </div>
                        <div class='col-auto'>
                          <BsCalendarCheck />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-xl-3 col-md-6 mb-4'>
                  <div class='card border-left-success shadow h-100 py-2'>
                    <div class='card-body'>
                      <div class='row no-gutters align-items-center'>
                        <div class='col mr-2'>
                          <div class='text-xs font-weight-bold text-success text-uppercase mb-1'>Earnings (Annual)</div>
                          <div class='h5 mb-0 font-weight-bold text-gray-800'>$215,000</div>
                        </div>
                        <div class='col-auto'>
                          <RiMoneyDollarCircleFill />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-xl-3 col-md-6 mb-4'>
                  <div class='card border-left-info shadow h-100 py-2'>
                    <div class='card-body'>
                      <div class='row no-gutters align-items-center'>
                        <div class='col mr-2'>
                          <div class='text-xs font-weight-bold text-info text-uppercase mb-1'>Tasks</div>
                          <div class='row no-gutters align-items-center'>
                            <div class='col-auto'>
                              <div class='h5 mb-0 mr-3 font-weight-bold text-gray-800'>50%</div>
                            </div>
                            <div class='col'>
                              <div class='progress progress-sm mr-2'>
                                <div
                                  class='progress-bar bg-info'
                                  role='progressbar'
                                  aria-valuenow='50'
                                  aria-valuemin='0'
                                  aria-valuemax='100'
                                  style={{ width: '50%' }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='col-auto'>
                          <BiTask />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='col-xl-3 col-md-6 mb-4'>
                  <div class='card border-left-warning shadow h-100 py-2'>
                    <div class='card-body'>
                      <div class='row no-gutters align-items-center'>
                        <div class='col mr-2'>
                          <div class='text-xs font-weight-bold text-warning text-uppercase mb-1'>Pending Requests</div>
                          <div class='h5 mb-0 font-weight-bold text-gray-800'>18</div>
                        </div>
                        <div class='col-auto'>
                          <BiComment />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class='row'>
                <div class='col-xl-8 col-lg-7'>
                  <div class='card shadow mb-4'>
                    <div class='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
                      <h6 class='m-0 font-weight-bold text-primary'>Language Name</h6>
                      <div class='dropdown no-arrow'>
                        <BsPencilSquare />
                      </div>
                    </div>
                    <div class='card-body'>
                      <Editor
                        toolbarClassName='toolbarClassName'
                        wrapperClassName='wrapperClassName'
                        editorClassName='editorClassName'
                      />
                    </div>
                  </div>
                </div>

                <div class='col-xl-4 col-lg-5'>
                  <div class='card shadow mb-4'>
                    <div class='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
                      <h6 class='m-0 font-weight-bold text-primary'>Update</h6>
                      <div class='dropdown no-arrow'>lokesh</div>
                    </div>
                    <div class='card-body'>lokesh</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
