import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Header from './Components/Header';
import './Style.css';
import Home from './Components/Home/Home';
import Footer from './Components/Footer';
import C from './Course/C';
import Admin from './Components/AdminPannel/Admin';
import LoginAdmin from './Components/Login/LoginAdmin';

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          {/* <Route path='*' element={<Home to='/' replace />} /> */}
          <Route path='/' element={<Home />} />
          <Route path='/C' element={<C />} />
          <Route path='/login' element={<LoginAdmin />} />
          <Route path='/admin/admin' element={<Admin />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
