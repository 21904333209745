import React from 'react';

const Contentarea = ({ selectedContent }) => {
  return (
    <div>
      <div>
        <h4 className='programname'>Heading</h4>
        <nav aria-label='Page navigation example'>
          <ul className='pagination justify-content-center'>
            <li className='page-item1'>
              <a className='page-link' href='#' tabindex='-1' aria-disabled='false'>
                &#8249;Previous
              </a>
            </li>

            <li className='page-item2'>
              <a className='page-link' href='#'>
                Next&#8250;
              </a>
            </li>
          </ul>
        </nav>
        <p>Description</p>
        <h6 className='outputtitle'>Code:-</h6>
        <div className='description'>
          <p>Code</p>
        </div>
        <h6 className='outputtitle'>Output:-</h6>
        <div className='outputdisplay'>
          <p>Output</p>
        </div>
      </div>
    </div>
  );
};

export default Contentarea;
