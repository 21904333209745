import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [toggleicon, setToggleicon] = useState(true);
  const [crossicon, setCrossicon] = useState(false);
  const [subTopics, setSubTopics] = useState([]);
  const [selectedContent, setSelectedContent] = useState({
    _id: '',
    topic_id: '',
    subtopic_name: '',
    code: '',
    description: '',
    output: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:3002/course/get_subtopic?id=6522f7404e8e5aa51d50935f');
        if (response.ok) {
          const data = await response.json();
          setSubTopics(data.data[0].sub_topic_name);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSidebarItemClick = async (subtopic_name, selectedItemId = '6522f7404e8e5aa51d50935f') => {
    try {
      console.log("Subtopic name:", subtopic_name);
      const data = await fetch(`http://localhost:3002/course/get_subtopic_details?id=6522f7404e8e5aa51d50935f&subtopic_name=${subtopic_name}`);
      console.log("Response:", data);
      if (data.ok) {
        const data = await data.json();
        if (data.error === false && data.data.length > 0) {
          const selectedData = data.data[0];
          setSelectedContent({
            _id: selectedData._id,
            topic_id: selectedData.topic_id,
            subtopic_name: selectedData.subtopic_name,
            code: selectedData.code,
            description: selectedData.description,
            output: selectedData.output,
          });
        } else {
          throw new Error('No data available for the selected subtopic');
        }
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      <div className='navbar-sidebar sidebar_bg_deisgn'>
        <button
          class='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#mysidebar'
          aria-controls='mysidebar'
          aria-expanded='false'
          aria-label='Toggle navigation'
          style={{ background: 'black' }}
        >
          {toggleicon && (
            <img
              src='/images/toggle_icon_sidebar.png'
              className='toggle_icon2'
              width='250'
              alt='toggle_icon'
              onClick={() => {
                setCrossicon(true);
                setToggleicon(false);
              }}
            />
          )}
          {crossicon && (
            <img
              src='/images/Close_icon.png'
              className='toggle_icon3'
              width='250'
              alt='toggle_icon'
              onClick={() => {
                setToggleicon(true);
                setCrossicon(false);
              }}
            />
          )}
        </button>
      </div>
      <div className='language_name'>
        <p>C Language</p>
      </div>

      <div className='collapse vertical-menu' id='mysidebar'>
        {console.log(subTopics)}
        {Array.isArray(subTopics) && subTopics.length > 0 ? (
          subTopics.map((subTopic, index) => (
            <Link to='' key={index} onClick={() => handleSidebarItemClick(subTopic)}>
              {subTopics[index]}
            </Link>
          ))
        ) : (
          <p>No subtopics available</p>
        )}
      </div>
    </>
  );
};

export default Sidebar;











// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';

// const Sidebar = () => {
//   const [toggleicon, setToggleicon] = useState(true);
//   const [crossicon, setCrossicon] = useState(false);
//   const [subTopics, setSubTopics] = useState([]);
//   const [selectedContent, setSelectedContent] = useState({
//     _id: '',
//     topic_id: '',
//     subtopic_name: '',
//     code: '',
//     description: '',
//     output: '',
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch('http://localhost:3002/course/get_subtopic?id=6522f7404e8e5aa51d50935f');
//         if (response.ok) {
//           const data = await response.json();
//           setSubTopics(data.data[0].sub_topic_name);
//         } else {
//           throw new Error('Failed to fetch data');
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleSidebarItemClick = async (subtopic_name, selectedItemId = '6522f7404e8e5aa51d50935f') => {
//     try {
//       console.log("Subtopic name:", subtopic_name);
//       const data = await fetch(`http://localhost:3002/course/get_subtopic_details?id=6522f7404e8e5aa51d50935f&subtopic_name=Introduction of C`);
//       console.log("Response:", data);
//       if (data.ok) {
//         const data = await data.json();
//         if (data.error === false && data.data.length > 0) {
//           const selectedData = data.data[0];
//           setSelectedContent({
//             _id: selectedData._id,
//             topic_id: selectedData.topic_id,
//             subtopic_name: selectedData.subtopic_name,
//             code: selectedData.code,
//             description: selectedData.description,
//             output: selectedData.output,
//           });
//         } else {
//           throw new Error('No data available for the selected subtopic');
//         }
//       } else {
//         throw new Error('Failed to fetch data');
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   return (
//     <>
//       <div className='navbar-sidebar sidebar_bg_deisgn'>
//         <button
//           class='navbar-toggler'
//           type='button'
//           data-bs-toggle='collapse'
//           data-bs-target='#mysidebar'
//           aria-controls='mysidebar'
//           aria-expanded='false'
//           aria-label='Toggle navigation'
//           style={{ background: 'black' }}
//         >
//           {toggleicon && (
//             <img
//               src='/images/toggle_icon_sidebar.png'
//               className='toggle_icon2'
//               width='250'
//               alt='toggle_icon'
//               onClick={() => {
//                 setCrossicon(true);
//                 setToggleicon(false);
//               }}
//             />
//           )}
//           {crossicon && (
//             <img
//               src='/images/Close_icon.png'
//               className='toggle_icon3'
//               width='250'
//               alt='toggle_icon'
//               onClick={() => {
//                 setToggleicon(true);
//                 setCrossicon(false);
//               }}
//             />
//           )}
//         </button>
//       </div>
//       <div className='language_name'>
//         <p>C Language</p>
//       </div>

//       <div className='collapse vertical-menu' id='mysidebar'>
//         {console.log(subTopics)}
//         {Array.isArray(subTopics) && subTopics.length > 0 ? (
//           subTopics.map((subTopic, index) => (
//             <Link to='' key={index} onClick={() => handleSidebarItemClick()}>
//               {subTopics[index]}
//             </Link>
//           ))
//         ) : (
//           <p>No subtopics available</p>
//         )}
//       </div>
//     </>
//   );
// };

// export default Sidebar;
